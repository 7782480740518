import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import TokenMigration from './TokenMigration';
import AboutHuddlz from './AboutHuddlz';

// Create a wrapper component to use the useLocation hook
function AppWrapper() {
  const location = useLocation(); // Use the useLocation hook to get the current location
  const noNavbarRoutes = ['/abouthuddlz'];

  return (
    <div className="App">
      {!noNavbarRoutes.includes(location.pathname) && (
        <header className="App-header">
          <TokenMigration />
        </header>
      )}
      <Routes>
        {/* <Route path="/" element={<TokenMigration />} /> */}
        <Route path="/abouthuddlz" element={<AboutHuddlz />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppWrapper /> {/* Use the AppWrapper component inside the Router */}
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import TokenMigrationABI from './contracts/TokenMigrationABI.json';
import ERC20ABI from './contracts/ERC20ABI.json';
import tokenOptions from './contracts/tokens.json';
import './styles/TokenMigration.css';

function formatAddress(address) {
    return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
}

function TokenMigration() {
    const navigate = useNavigate();
    const [account, setAccount] = useState(null);
    const [contract, setContract] = useState(null);
    const [selectedToken, setSelectedToken] = useState('');
    const [amount, setAmount] = useState('');
    const [tokenBalance, setTokenBalance] = useState('0');
    const [isConnected, setIsConnected] = useState(false);
    const [transactionMessage, setTransactionMessage] = useState('');
    const [isApproved, setIsApproved] = useState({});
    const web3 = new Web3(window.ethereum);
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const BSC_MAINNET_CHAIN_ID = '0x38'; // 56 in hexadecimal

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', (chainId) => {
                if (chainId !== BSC_MAINNET_CHAIN_ID) {
                    checkAndSwitchNetwork();
                }
            });
        }
    }, []);

    useEffect(() => {
        // Clears the transaction message after 3 seconds
        if (transactionMessage) {
            const timer = setTimeout(() => setTransactionMessage(''), 3000);
            return () => clearTimeout(timer); // Cleanup function to clear the timer
        }
    }, [transactionMessage]);

    useEffect(() => {
        const fetchTokenBalance = async () => {
            if (account && selectedToken) {
                const tokenContract = new web3.eth.Contract(ERC20ABI, selectedToken);
                try {
                    const balance = await tokenContract.methods.balanceOf(account).call();
                    setTokenBalance(web3.utils.fromWei(balance, 'ether'));
                } catch (error) {
                    console.error('Error fetching token balance:', error);
                    setTokenBalance('0');
                }
            }
        };

        fetchTokenBalance();
    }, [account, selectedToken]);

    useEffect(() => {
        const initializeApprovalStatus = async () => {
            if (account && contractAddress) {
                const approvalStatus = {};
                for (const token of tokenOptions) {
                    const tokenContract = new web3.eth.Contract(ERC20ABI, token.address);
                    try {
                        const allowance = await tokenContract.methods.allowance(account, contractAddress).call();
                        approvalStatus[token.address] = allowance !== '0';
                    } catch (error) {
                        console.error("Error checking allowance for token:", token.ticker, error);
                        approvalStatus[token.address] = false;
                    }
                }
                setIsApproved(approvalStatus);
            }
        };

        initializeApprovalStatus();
    }, [account, contractAddress, web3.eth.Contract]);

    useEffect(() => {
        const checkApprovalStatus = async () => {
            if (account && selectedToken && contractAddress) {
                const tokenContract = new web3.eth.Contract(ERC20ABI, selectedToken);
                try {
                    const allowance = await tokenContract.methods.allowance(account, contractAddress).call();
                    setIsApproved(prev => ({ ...prev, [selectedToken]: allowance !== '0' }));
                } catch (error) {
                    console.error("Error checking allowance:", error);
                }
            }
        };

        checkApprovalStatus();
    }, [account, selectedToken, contractAddress]);

    const checkAndSwitchNetwork = async () => {
        try {
            if (window.ethereum.networkVersion !== BSC_MAINNET_CHAIN_ID) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: BSC_MAINNET_CHAIN_ID }],
                    });
                } catch (switchError) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: BSC_MAINNET_CHAIN_ID,
                                        rpcUrl: 'https://bsc-dataseed.binance.org/' // ... other parameters like chain name, symbol, explorer, etc.
                                    },
                                ],
                            });
                        } catch (addError) {
                            // Handle errors when trying to add a new network
                            console.error('Failed to add BSC network', addError);
                        }
                    } else {
                        console.error('Failed to switch to BSC network', switchError);
                    }
                }
            }
        } catch (err) {
            console.error('Error checking network', err);
        }
    };

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                setAccount(accounts[0]);
                setIsConnected(true);
                checkAndSwitchNetwork(); // Check and prompt network change
                // Resetting the approval status for the new account
                setIsApproved({});
                const tokenMigrationContract = new web3.eth.Contract(TokenMigrationABI, contractAddress);
                setContract(tokenMigrationContract);
            } catch (error) {
                alert('You must allow MetaMask.');
                console.error('Error accessing MetaMask', error);
            }
        } else {
            alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    };

    const approveTokensHandler = async () => {
        if (!selectedToken || !amount) {
            setTransactionMessage("Please select a token and enter an amount.");
            return;
        }

        const tokenContract = new web3.eth.Contract(ERC20ABI, selectedToken);
        const maxApproval = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

        try {
            await tokenContract.methods.approve(contractAddress, maxApproval).send({ from: account });
            setTransactionMessage('Approval successful!');
            // Correctly updating the approval status for the selected token
            setIsApproved(prev => ({ ...prev, [selectedToken]: true }));
        } catch (error) {
            console.error('Approval transaction error:', error);
            setTransactionMessage(`Approval failed: ${error.message}`);
        }
    };

    const migrateTokensHandler = async () => {
        if (!contract || !account || !amount || !selectedToken) {
            setTransactionMessage("Please connect, select a token, and specify an amount.");
            return;
        }

        try {
            // Convert amount to Wei or the smallest unit of the token
            const amountInWei = web3.utils.toWei(amount, 'ether');
            await contract.methods.migrateTokens(selectedToken, amountInWei).send({ from: account });
            setTransactionMessage('Tokens migrated successfully!');
        } catch (error) {
            setTransactionMessage('Migration failed!');
            console.error('Error migrating tokens:', error);
        }
    };

    const handleTokenChange = (e) => {
        setSelectedToken(e.target.value);
        // Do not reset isApproved here; it's now handled in the useEffect above
    };

    const goToAboutPage = () => navigate('/abouthuddlz');

    return (
        <div className="token-migration-container">
            <h1 className="token-migration-header">Token Migration</h1>
            <p className="text-gray-600 text-sm text-center">Migrate your $SWG, $SWGb and $SWFI tokens to the new $HUDL</p>
            <div className="mt-4">
                {!isConnected ? (
                    <button onClick={connectWalletHandler} className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-400 focus:outline-none">
                        Connect Wallet
                    </button>
                ) : (
                    <>
                        <div className="flex justify-between items-center mt-4">
                            <span className="text-gray-700">Connected with:</span>
                            <span className="text-sm text-blue-600 bg-blue-100 rounded-full px-3 py-1">{formatAddress(account)}</span>
                        </div>
                        {selectedToken && (
                            <div className="flex justify-between items-center mt-4">
                                <span className="text-gray-700">Balance:</span>
                                <span className="text-sm text-green-600 bg-green-100 rounded-full px-3 py-1">{tokenBalance}</span>
                            </div>
                        )}
                        {transactionMessage && (
                            <div className="p-2 text-sm text-red-600 bg-red-100 rounded-lg mt-4 text-center">
                                {transactionMessage}
                            </div>
                        )}
                    </>
                )}
                {isConnected && (
                    <div className="mt-4">
                        <select value={selectedToken} onChange={handleTokenChange} className="border bg-white rounded px-3 py-2 outline-none w-full mt-2">
                            <option value="">Select a token</option>
                            {tokenOptions.map((token, index) => (
                                <option key={index} value={token.address}>{token.ticker}</option>
                            ))}
                        </select>

                        <input
                            type="number"
                            placeholder="Amount to Migrate"
                            value={amount}
                            className="border bg-white rounded outline-none mt-2"
                            onChange={(e) => setAmount(e.target.value)}
                        />

                        <button
                            onClick={approveTokensHandler}
                            className="w-full mt-4 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400"
                        >
                            Approve
                        </button>

                        <button
                            onClick={migrateTokensHandler}
                            className="w-full mt-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-500 rounded-md hover:bg-green-400 focus:outline-none focus:bg-green-400"
                        >
                            Migrate Tokens
                        </button>

                        <button
                            onClick={goToAboutPage}
                            className="w-full mt-2 px-4 py-2 tracking-wide text-gray-700 transition-colors duration-200 transform border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                        >
                            About $HUDL
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TokenMigration;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/AboutHuddlz.css'; // Assuming you have a separate CSS file for styling

const AboutHuddlz = () => {
    const navigate = useNavigate();
    const migratePage = () => navigate('/');
    return (
        <div className="about-container">
            <section className="intro-section">
                <h1>Welcome to $HUDL Token</h1>
                <p>HuddlzToken ($HUDL) revolutionizes the way we think about cryptocurrency rewards and community engagement. With a unique reflection mechanism, $HUDL incentivizes holding and participating in the ecosystem.</p>
            </section>

            <section className="details-section">
                <h2>Empowering Community Through Rewards</h2>
                <p>$HUDL introduces a pioneering transaction tax system where each transfer supports the token’s growth and rewards its holders. Here’s how it works:</p>

                <h3>Transaction Tax Breakdown</h3>
                <ul>
                    <li><strong>$HUDL Token Burn:</strong> 1% of every transaction is set aside to be burned weekly.</li>
                    <li><strong>Marketing Funds:</strong> 1% goes directly into marketing funds.</li>
                    <li><strong>$HUDL Rewards:</strong> 2% of the transaction tax is distributed among $HUDL holders.</li>
                    <li><strong>Liquidity Funds:</strong> 2% is allocated to liquidity funds on PancakeSwap DEX.</li>
                </ul>
                <p>Join us in shaping the future of community-driven finance, where every transaction enriches our collective journey.</p>

                <h3>Join the $HUDL Journey</h3>
                <p>Token Name: HuddlzToken<br />
                    Token Symbol: HUDL<br />
                    Max Supply: 8,000,000<br />
                    Contract Address: <a href="https://bscscan.com/token/0x1928d99f8a13f851ec9e5bd87181988722eefcd8" target="_blank" rel="noopener noreferrer">View on BscScan</a></p>
            </section>
            <button onClick={migratePage}>Migrate To $HUDL</button>
        </div>
    );
};

export default AboutHuddlz;
